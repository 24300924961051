<template>
    <div>
        <v-dialog v-model="open" v-if="open" persistent scrollable max-width="920px" min-height="500px">
            <v-card>
                <v-app-bar flat dark color="primary">
                    <v-app-bar-nav-icon v-if="!isNotMobile" @click.stop="showMenu = !showMenu"></v-app-bar-nav-icon>
                    <span v-if="textHeader">{{ textHeader }}</span>
                    <v-toolbar-items v-if="isNotMobile">
                        <v-btn :class="{ 'blue darken-3': showModal == 1 ? true : false }" dark text
                            @click.native="activeClick(1)">CLIENTE</v-btn>
                        <v-btn :class="{ 'blue darken-3': showModal == 2 ? true : false }" @click.native="activeClick(2)"
                            text>DATOS</v-btn>
                        <v-btn v-if="reclamo" :class="{ 'blue darken-3': showModal == 3 ? true : false }"
                            @click.native="activeClick(3)" text>ESTADOS </v-btn>
                    </v-toolbar-items>

                    <v-menu v-else v-model="showMenu" transition="slide-y-transition" bottom>
                        <v-list class="blue">
                            <v-list-item dark :class="{ 'blue darken-3 ': showModal == 1 ? true : false }"
                                @click.native="activeClick(1, 'CLIENTE')">
                                <v-list-item-title>CLIENTE</v-list-item-title>
                            </v-list-item>
                            <v-list-item dark :class="{ 'blue darken-3 ': showModal == 2 ? true : false }"
                                @click.native="activeClick(2, 'DATOS')">
                                <v-list-item-title>DATOS</v-list-item-title>
                            </v-list-item>
                            <v-list-item dark :class="{ 'blue darken-3 ': showModal == 3 ? true : false }"
                                @click.native="activeClick(3, 'ESTADOS')">
                                <v-list-item-title>ESTADOS</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-chip v-if="reclamo && datos.id" small color="secondaryTheme" class="caption" dark>{{
                                            _getFormatId(datos.id) }}</v-chip>

                    <v-btn small class="red" icon @click.native="cerrarModal"> <v-icon small>mdi-close</v-icon> </v-btn>
                </v-app-bar>
                <v-divider></v-divider>
                <v-card-text>
                    <!-- <v-container> -->
                    <v-row dense class="py-2">
                        <v-col cols="12">
                            <v-container>
                                <template v-if="showModal === 1">
                                    <form @submit.prevent="nextAction" :data-vv-scope="isForm" :id="isForm"
                                        v-if="showModal === 1">
                                        <v-row align="center">
                                            <v-col cols="12" sm="6" md="3">
                                                <v-select clearable v-model="reclamoManual.isTipoDocumentoCliente"
                                                    v-validate="'required'" required dense hide-details="auto" outlined
                                                    :items="tipoDocumento" item-text="text" item-value="id"
                                                    :error-messages="errors.collect('reclamoManual.isTipoDocumentoCliente')"
                                                    label="Tipo de documento*"
                                                    data-vv-name="reclamoManual.isTipoDocumentoCliente"
                                                    data-vv-as="Tipo Documento" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field v-model="reclamoManual.isNumeroDocumentoCliente"
                                                    v-validate="`required|numeric${cantTipoDocumento}`" dense
                                                    hide-details="auto" outlined label="Nro Documento*"
                                                    :error-messages="errors.collect('reclamoManual.isNumeroDocumentoCliente')"
                                                    data-vv-name="reclamoManual.isNumeroDocumentoCliente"
                                                    data-vv-as="Numero Documento" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field v-model="reclamoManual.firstName" dense outlined
                                                    v-validate="'required'" required hide-details="auto" label="Nombre*"
                                                    :error-messages="errors.collect('reclamoManual.firstName')"
                                                    data-vv-name="reclamoManual.firstName" data-vv-as="Nombre" />
                                            </v-col>
                                            <v-col v-if="!reclamo" cols="12" sm="6" md="3">
                                                <v-text-field v-model="reclamoManual.isPaterno" v-validate="'required'"
                                                    required dense outlined hide-details="auto" label="Apellido Paterno*"
                                                    :error-messages="errors.collect('reclamoManual.isPaterno')"
                                                    data-vv-name="reclamoManual.isPaterno" data-vv-as="Apellido Paterno" />
                                            </v-col>
                                            <v-col v-if="!reclamo" cols="12" sm="6" md="3">
                                                <v-text-field v-model="reclamoManual.isMaterno" v-validate="'required'"
                                                    required dense outlined hide-details="auto" label="Apellido Materno*"
                                                    :error-messages="errors.collect('reclamoManual.isMaterno')"
                                                    data-vv-name="reclamoManual.isMaterno" data-vv-as="Apellido Materno" />
                                            </v-col>

                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field :error-messages="errors.collect('reclamoManual.isTelefono')"
                                                    v-validate="'numeric'" data-vv-name="reclamoManual.isTelefono"
                                                    data-vv-as="isTelefono" v-model="reclamoManual.isTelefono"
                                                    hide-details="auto" dense outlined label="Telefono" />
                                            </v-col>

                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field clearable
                                                    :error-messages="errors.collect('reclamoManual.email')"
                                                    data-vv-name="reclamoManual.email" data-vv-as="Correo"
                                                    v-model="reclamoManual.email" hide-details="auto" dense outlined
                                                    label="Correo" />
                                            </v-col>

                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete clearable autocomplete="off" v-validate="'required'"
                                                    required
                                                    :error-messages="errors.collect('reclamoManual.isDepartamento')"
                                                    data-vv-name="reclamoManual.isDepartamento" data-vv-as="Departamento"
                                                    v-model="reclamoManual.isDepartamento" dense hide-details="auto"
                                                    outlined :items="departamentoAssign" item-text="descripcion"
                                                    item-value="idDepa" label="Departamento*">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete clearable autocomplete="off" v-validate="'required'"
                                                    required :error-messages="errors.collect('reclamoManual.isProvincia')"
                                                    data-vv-name="reclamoManual.isProvincia" data-vv-as="Provincia"
                                                    v-model="reclamoManual.isProvincia" dense hide-details="auto" outlined
                                                    :items="provincias" item-text="descripcion" item-value="idProv"
                                                    label="Provincia*">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete clearable autocomplete="off" v-validate="'required'"
                                                    required :error-messages="errors.collect('reclamoManual.isDistrito')"
                                                    data-vv-name="reclamoManual.isDistrito" data-vv-as="Distrito"
                                                    v-model="reclamoManual.isDistrito" dense hide-details="auto" outlined
                                                    :items="distritos" item-text="descripcion" item-value="idDist"
                                                    label="Distrito*">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field :error-messages="errors.collect('reclamoManual.idDireccion')"
                                                    data-vv-name="reclamoManual.idDireccion" data-vv-as="Direccion"
                                                    v-model="reclamoManual.idDireccion" hide-details="auto" dense outlined
                                                    label="Dirección" />
                                            </v-col>
                                        </v-row>
                                    </form>
                                </template>

                                <template v-else-if="showModal === 2">
                                    <form @submit.prevent="nextAction" :data-vv-scope="isForm" :id="isForm"
                                        v-if="showModal === 2">
                                        <v-row align="center">
                                            <v-col v-if="reclamo" cols="12">
                                                <h6 class="red--text">* Data ingresada por el cliente / No debe ser editable
                                                </h6>
                                            </v-col>
                                            <v-col v-else cols="12" sm="6" md="6">
                                                <v-checkbox v-model="checkbox" label="Registrar LR Manual?"></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" sm="12" :md="reclamo ? 10 : 12">
                                                <v-text-field v-if="checkbox || reclamo" v-validate="'required'" required
                                                    v-model="reclamoManual.idComplaintsExternal"
                                                    :error-messages="errors.collect('reclamoManual.idComplaintsExternal')"
                                                    data-vv-name="reclamoManual.idComplaintsExternal"
                                                    data-vv-as="idReclamoManual" dense hide-details="auto"
                                                    placeholder="LBRM001" outlined label="Ingresar Código Reclamo" />
                                            </v-col>
                                            <v-col v-if="reclamo" md="2">
                                                <v-btn small color="primary" :loading="loadingStep" dark
                                                    @click="updateCodeComplaints">Actualizar</v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete v-validate="'required'" required
                                                    v-model="reclamoManual.isBussines"
                                                    :error-messages="errors.collect('reclamoManual.isBussines')"
                                                    data-vv-name="reclamoManual.isBussines" data-vv-as="Empresa" dense
                                                    hide-details="auto" outlined :items="listBussines"
                                                    item-text="description" item-value="id" label="Empresa" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete v-validate="'required'" required
                                                    v-model="reclamoManual.isTipoBien"
                                                    :error-messages="errors.collect('reclamoManual.isTipoBien')"
                                                    data-vv-name="reclamoManual.isTipoBien" data-vv-as="Tipo Bien" dense
                                                    hide-details="auto" :items="tipoBien" item-text="label"
                                                    item-value="value" outlined label="Tipo Bien" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field v-validate="'required'" required
                                                    v-model="reclamoManual.isAltProductoServicio"
                                                    :error-messages="errors.collect('reclamoManual.isAltProductoServicio')"
                                                    data-vv-name="reclamoManual.isAltProductoServicio"
                                                    data-vv-as="Producto Servicio" dense outlined hide-details="auto"
                                                    label="Producto / Servicio" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-text-field v-validate="'numeric'" v-model="reclamoManual.isMonto"
                                                    hide-details="auto" dense outlined label="Monto" />
                                            </v-col>

                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete v-validate="'required'" required
                                                    :error-messages="errors.collect('reclamoManual.isIdPremises')"
                                                    data-vv-name="reclamoManual.isIdPremises" data-vv-as="Sede"
                                                    v-model="reclamoManual.isIdPremises" hide-details="auto"
                                                    :items="listPremises" item-text="description" item-value="id" dense
                                                    outlined label="Sede" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3"
                                                v-if="reclamoManual.isIdPremises == 16 && !reclamo">
                                                <v-text-field v-validate="'required'" required
                                                    :error-messages="errors.collect('reclamoManual.isAltPremises')"
                                                    data-vv-name="reclamoManual.isAltPremises" data-vv-as="Otra Sede"
                                                    v-model="reclamoManual.isAltPremises" hide-details="auto" dense outlined
                                                    label="Otra Sede" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete
                                                    :error-messages="errors.collect('reclamoManual.isTipoReclamacion')"
                                                    data-vv-name="reclamoManual.isTipoReclamacion"
                                                    data-vv-as="Tipo Reclamacion" v-validate="'required'" required
                                                    v-model="reclamoManual.isTipoReclamacion" :items="tipoReclamo"
                                                    item-text="label" item-value="value" dense hide-details="auto" outlined
                                                    label="Tipo Reclamacion" />
                                            </v-col>

                                            <v-col cols="12" sm="6" md="3">
                                                <v-autocomplete v-validate="'required'" required
                                                    :error-messages="errors.collect('reclamoManual.isTipoReclamoQueja')"
                                                    data-vv-name="reclamoManual.isTipoReclamoQueja"
                                                    data-vv-as="Reclamo o Queja" v-model="reclamoManual.isTipoReclamoQueja"
                                                    :items="tipoDetalleReclamo" item-text="label" item-value="value" dense
                                                    hide-details="auto" outlined label="Tipo Reclamo o Queja" />
                                            </v-col>
                                            <v-col v-if="reclamoManual.isTipoReclamoQueja == 3 && !reclamo">
                                                <v-text-field v-validate="'required'" required
                                                    :error-messages="errors.collect('reclamoManual.isOtroTipoReclamo')"
                                                    data-vv-name="reclamoManual.isOtroTipoReclamo"
                                                    data-vv-as="Otro Tipo Reclamo" v-model="reclamoManual.isOtroTipoReclamo"
                                                    dense hide-details="auto" outlined label="Especificar otro tipo" />
                                            </v-col>

                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea v-validate="'required'" required
                                                    :error-messages="errors.collect('reclamoManual.isDescripcionReclamo')"
                                                    data-vv-name="reclamoManual.isDescripcionReclamo"
                                                    data-vv-as="Descripcion" autocomplete="off"
                                                    v-model="reclamoManual.isDescripcionReclamo" dense hide-details="auto"
                                                    outlined label="Descripcion" />
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea v-validate="'required'" required
                                                    :error-messages="errors.collect('reclamoManual.isPedido')"
                                                    data-vv-name="reclamoManual.isPedido" data-vv-as="Pedido"
                                                    v-model="reclamoManual.isPedido" hide-details="auto" dense outlined
                                                    label="Pedido" />
                                            </v-col>
                                        </v-row>
                                    </form>
                                </template>
                                <template v-else-if="showModal === 3">
                                    <template v-if="loadingItems">
                                        <ItemEstadosComplaint :step="step" :dataComplaints="datos" />
                                    </template>
                                    <template v-else>
                                        <v-progress-circular :size="50" color="primary" indeterminate>
                                        </v-progress-circular>
                                    </template>
                                </template>
                            </v-container>
                        </v-col>
                    </v-row>
                    <!-- </v-container> -->
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions class="grey lighten-2" align="center" justify="center">
                    <v-row v-if="tipo != 1" dense>
                        <v-col cols="12" sm="12">
                            <v-row v-if="reclamo">
                                <v-col cols="6" v-if="showModal != 1" sm="6">
                                    <v-btn small v-if="showModal != 1" color="primary" :loading="loading" dark
                                        @click="downStep">Atras</v-btn>
                                </v-col>
                                <template v-if="showNext">
                                    <v-col cols="6" :sm="showModal > 1 ? 6 : 12">
                                        <v-btn small v-if="showModal != 1" color="primary" :loading="loadingStep" dark
                                            @click="nextStep">Siguiente</v-btn>

                                        <v-btn small v-else color="primary" :loading="loadingStep" dark
                                            @click="nextStep">Actualizar</v-btn>
                                    </v-col>
                                </template>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="6" sm="4">
                                    <v-btn small color="red" :loading="loading" v-if="showModal == 2" dark
                                        @click="downStep">Atras</v-btn>
                                </v-col>
                                <v-spacer v-if="showModal == 2"></v-spacer>
                                <template v-if="showModal > 0">
                                    <v-col cols="6" sm="4">
                                        <v-btn color="green" small :loading="loadingRegistro" type="submit" dark
                                            :form="isForm">{{ textBTN }}</v-btn>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="openConfirmation" persistent max-width="260px" min-height="400px">
            <v-card class="blue darken-2">
                <v-card-text class="py-3">
                    <h3 class="white--text text-center" color="white">Reclamo Creado Exitosamente</h3>
                </v-card-text>

                <v-card-actions class="blue-grey lighten-4">
                    <v-spacer />

                    <v-btn color="red" dark @click.native="cerrarModal">cerrar</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import ItemEstadosComplaint from '@/components/crud/ItemEstadosComplaint'
import { format, subDays } from 'date-fns'
import DefaultModel from '@/models/reclamaciones'
import axios from 'axios'
import { getFormatId } from '@/utils/genericUtils'
const { VUE_APP_BASIC_USER, VUE_APP_BASIC_PASS } = process.env


export default {
    props: ['open', 'loadingBTN', 'textButton', 'step', 'reclamo', 'tipo'],
    mixins: [mixins],
    components: {
        ItemEstadosComplaint
    },
    data() {
        return {
            checkbox: false,
            cantTipoDocumento: '',
            showMenu: false,
            loadingItems: true,
            reclamoManual: DefaultModel.BaseFormManual(),
            openConfirmation: false,
            loadingStep: false,
            showModal: 1,
            fechaFiltro: [format(subDays(new Date(), 30), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')],
            loading: false,
            textHeader: '',
            loadingRegistro: false
        }
    },
    computed: {
        ...mapGetters({
            listaModelos: 'productos/lista',
            listaEstados: 'estados/lista',
            optionalRole: 'usuarios/optionalRole',
            departamentoAssign: 'ubigeo/departamentoAssign'
        }),
        ...mapGetters('reclamaciones', ['lista']),
        ...mapState('reclamaciones', ['datos', 'listBussines', 'listPremises','itemsDocumento','tipoBien','tipoReclamo','tipoDetalleReclamo']),
        ...mapState('ubigeo', ['provincias', 'distritos']),
        ...mapState('utilities', ['tipoDocumento']),
        idTipoProspecto() {
            return this.datos.idTipoProspecto
        },
        resSede() {
            return this.datos.local == 'OTRO' ? this.datos.altPremises : this.datos.local
        },
        isForm() {
            return this.showModal == 2 ? 'form-2' : 'form-1'
        },

        idMarca() {
            console.log('this.datos', this.datos)
            console.log('this.datos.idMarca ', this.datos.idMarca)
            return this.datos.idMarca
        },
        isDepartamento() {
            return this.reclamoManual.isDepartamento
        },
        idCompalintCheckbox() {
            return this.checkbox
        },
        typeDocumento() {
            return this.reclamoManual.isTipoDocumentoCliente
        },
        isProvincia() {
            return this.reclamoManual.isProvincia
        },
        isDistrito() {
            return this.reclamoManual.isDistrito
        },

        textBTN() {
            return this.showModal == 2 ? 'CREAR RECLAMO' : 'CONTINUAR'
        },

        isNotMobile() {
            if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {
                return false
            } else {
                return true
            }
        },
        showNext() {
            if (this.showModal == 3) {
                return false
            } else {
                return true
            }
        }
    },
    watch: {
        idCompalintCheckbox(val) {
            if (!val) {
                this.reclamoManual.idComplaintsExternal = null
            }
        },
        typeDocumento(val) {
            if (val == 6) {
                this.cantTipoDocumento = '|digits:11'
            } else if (val == 1) {
                this.cantTipoDocumento = '|digits:8'
            } else {
                this.cantTipoDocumento = ''
            }
        },
        async idMarca() {
            console.log('watch this.datos.idMarca', this.datos.idMarca)
            await this.handleGetProducto()
        },

        async showModal(val) {
            console.log('val ', val, this.step)

            if (val == 2) {
                await this.fnHandleGet({})
            }
        },

        async isDepartamento(val) {
            console.log('isDepartamento ', val)
            if (val) {
                await this.handleGetProvincias({ id: val })
                if (this.step == 1) {
                    this.UPDATE_DATOS({
                        idDistrito: null
                    })
                }
            }
        },
        async isProvincia(val, old) {
            console.log('isProvincia ', val, ' old ', old)
            if (val) {
                await this.handleGetDistritos({ id: val })
            }
        }
    },
    methods: {
        ...mapMutations({
            UPDATE_DATOS: 'prospectos/UPDATE_DATOS'
        }),
        ...mapActions({
            handleGet: 'reclamaciones/obtenerLista',
            handleGetDepartamentos: 'ubigeo/obtenerListaDepartamentos',
            handleGetProvincias: 'ubigeo/obtenerListaPronvincias',
            handleGetDistritos: 'ubigeo/obtenerListaDistritos',
            handleGetProducto: 'productos/obtenerLista',
            handleGetEstados: 'estados/obtenerListaComplaints',
            handleGetOrigen: 'origen/obtenerLista',
            handleCrearClientes: 'clientes/registrar',
            handleActualizarClientes: 'clientes/actualizarLR',
            handlePutComplaints: 'reclamaciones/actualizarLR',
            handleGetMarcas: 'productos/getMarcas'
        }),
        async fnHandleGetComplaints(payload) {
            const {
                idBussines = null,
                idDepartamento = null,
                idNumeroDocumento = null,
                idProduct = null,
                idTipoReclamo = null,
                idHojaReclamo = null,
                idName = null
            } = payload
            await this.handleGet({
                fechaFiltro: this.fechaFiltro,
                idBussines,
                idDepartamento,
                idNumeroDocumento,
                idProduct,
                idTipoReclamo,
                idHojaReclamo,
                idName
            })
            await this.handleGetProducto({ fechaFiltro: this.fechaFiltro })
        },
        _getFormatId(id) {
            return getFormatId(id)
        },
        nextStep() {
            this.$validator.validateAll().then(async (result) => {
                console.log('result', result)
                if (result) {
                    this.loadingStep = true
                    const val = 1 + this.showModal
                    console.log('val ', val)
                    if (val == 2) {
                        console.log('this.reclamoManual', this.reclamoManual)
                        if (this.step == 1 && !this.reclamoManual.idPersonas) {
                            console.log('here-1')
                            //Create person
                            const per = await this.handleCrearClientes(this.reclamoManual)
                            this.UPDATE_DATOS({
                                idPersona: per.payload
                            })
                        } else {
                            //Update person
                            const {
                                isTipoDocumentoCliente,
                                isNumeroDocumentoCliente,
                                firstName,
                                isTelefono,
                                email,
                                isDepartamento,
                                isProvincia,
                                isDistrito,
                                idDireccion,
                                isBussines,
                                isTipoBien,
                                isAltProductoServicio,
                                isMonto,
                                isIdPremises,
                                isTipoReclamacion,
                                idComplaintsExternal
                            } = this.reclamoManual
                            await this.handleActualizarClientes({
                                ...this.reclamoManual,
                                nombreCompletos: this.reclamoManual.firstName,
                                id: this.reclamoManual.idPersonas,
                                idTipoDocumento: isTipoDocumentoCliente,
                                numeroDocumento: isNumeroDocumentoCliente,
                                nombreCompletos: firstName,
                                telefono: isTelefono,
                                correo: email,
                                idDepa: isDepartamento,
                                idProv: isProvincia,
                                idDist: isDistrito,
                                direccion: idDireccion,
                                idBussines: isBussines,
                                typeWell: isTipoBien,
                                altProductoServicio: isAltProductoServicio,
                                monto: isMonto,
                                idPremises: isIdPremises,
                                typeClaims: isTipoReclamacion,
                                idComplaintsExternal
                            })
                        }
                    }
                    if (val === 3) {
                        this.showModal = 3
                    } else {
                        this.showModal = val
                    }
                    this.loadingStep = false
                }
            })
        },
        async updateCodeComplaints() {
            this.loadingStep = true

            console.log('update')
            await this.handlePutComplaints({
                ...this.reclamoManual,
                idComplaintsExternal: this.reclamoManual.idComplaintsExternal
            })
            this.loadingStep = false
        },
        nextAction() {
            this.$validator
                .validateAll(this.isForm)
                .then(async (result) => {
                    console.log('result', result)
                    console.log('this.isForm ', this.isForm)
                    console.log('this.$validator', this.$validator)
                    console.log('this.showModal ', this.showModal)

                    if (result) {
                        if (this.showModal == 2) {
                            this.loadingRegistro = true
                            const response = await axios({
                                url: `/ms/servicios/cm/v2.0/complaints`,
                                auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                responseType: 'blob',
                                method: 'POST',
                                data: {
                                    ...this.reclamoManual,
                                    labelPremises: this.listPremises.find((x) => x.id == this.reclamoManual.isBussines).description,
                                    labelDepartamento: this.departamentoAssign.find((x) => x.idDepa == this.reclamoManual.isDepartamento).descripcion,
                                    labelDistrito: this.distritos.find((x) => x.idDist == this.reclamoManual.isDistrito).descripcion,
                                    labelProvincia: this.provincias.find((x) => x.idProv == this.reclamoManual.isProvincia).descripcion,
                                    labelBussines: this.listBussines.find((x) => x.id == this.reclamoManual.isBussines).description,
                                    labelSede: this.listPremises.find((x) => x.id == this.reclamoManual.isIdPremises).description
                                }
                            })
                            if (response.status == 200) {
                                this.openConfirmation = true
                            }
                            this.loadingRegistro = false
                        } else {
                            this.showModal = 2
                        }
                    }
                })
                .catch((e) => {
                    console.log('----e ', e)
                })
        },
        async fnHandleGet({ numeroDocumento = null, idCanal = null, idOrigen = null, usuario = null }) {
            await this.handleGetEstados({ status: false })
        },
        activeClick(header, text) {
            this.textHeader = text
            console.log('here--------', header)
            if (this.step == 2 || this.tipo == 1) {
                this.showModal = header
            }
        },
        async cerrarModal() {
            await this.fnHandleGetComplaints({})
            this.showModal = 1
            await this.$emit('closeModal', 'createDialog')
        },

        downStep() {
            const val = this.showModal - 1
            if (val === 0) {
                this.showModal = 1
            } else {
                this.showModal = val
            }
        }
    },
    async mounted() {
        console.log('CREATED_MODAL', this.datos)
        const {
            idTipoDocumento,
            numeroDocumento,
            nombreCompletos,
            telefono,
            correo,
            idDepa,
            idProv,
            idDist,
            direccion,
            idTipoProspecto,
            idMarca,
            idBussines,
            altProductoServicio,
            monto,
            idPremises,
            isTipoReclamoQueja,
            typeWell,
            typeClaims,
            isDescripcionReclamo,
            isPedido,
            idComplaintsExternal
        } = this.datos
        if (this.reclamo) {
            console.log('reclamo')
            this.reclamoManual = {
                ...this.datos,
                ...this.reclamoManual,
                isTipoDocumentoCliente: idTipoDocumento,
                isNumeroDocumentoCliente: numeroDocumento,
                firstName: nombreCompletos,
                isTelefono: telefono,
                email: correo,
                isDepartamento: idDepa,
                isProvincia: idProv,
                isDistrito: idDist,
                idDireccion: direccion,
                isBussines: idBussines,
                isTipoBien: typeWell == 'producto' ? 1 : 2,
                isAltProductoServicio: altProductoServicio,
                isMonto: monto,
                isIdPremises: idPremises,
                isTipoReclamacion: typeClaims == 'reclamo' ? 1 : 2,
                isTipoReclamoQueja: isTipoReclamoQueja == 'PRODUCTO' ? 1 : isTipoReclamoQueja == 'SERVICIO' ? 2 : 3,
                isDescripcionReclamo,
                isPedido,
                idComplaintsExternal
            }
        }
        //
        this.textHeader = null
        this.showMenu = false
        await this.handleGetDepartamentos()
        if (this.step == 2) {
            if (idProv) {
                await this.handleGetProvincias({ id: idDepa })
            }
            if (idDist) {
                await this.handleGetDistritos({ id: idProv })
            }
            if (idTipoProspecto) {
                await this.handleGetMarcas()
            }
            if (idMarca) {
                await this.handleGetProducto()
            }
        }
    }
}
</script>
