<template>
    <v-row class="blue lighten-5 my-2">
        <v-col cols="12" class="justify-center align-center">
            <form>
                <v-container>
                    <v-row class="align-center">
                        <v-col cols="6" md="2">
                            <v-select
                                clearable
                                v-validate="'required'"
                                :error-messages="errors.collect('datos.idEstado')"
                                data-vv-name="datos.idEstado"
                                required
                                dense
                                hide-details="auto"
                                v-model="formItemReclamaciones.idEstado"
                                :items="listaEstadosCom"
                                item-text="descripcion"
                                item-value="id"
                                label="Estados*"
                            />
                        </v-col>

                        <v-col cols="6" sm="3">
                            <v-textarea
                                v-model="formItemReclamaciones.area"
                                auto-grow
                                rows="1"
                                row-height="30"
                                dense
                                hide-details="auto"
                                label="Area"
                                required
                            />
                        </v-col>
                        <v-col cols="6" sm="3">
                            <v-textarea
                                v-model="formItemReclamaciones.descripcion"
                                auto-grow
                                rows="1"
                                row-height="30"
                                v-validate="'required'"
                                dense
                                hide-details="auto"
                                label="Reclamaciones*"
                                :error-messages="errors.collect('formItemReclamaciones.descripcion')"
                                data-vv-name="formItemReclamaciones.descripcion"
                                required
                            />
                        </v-col>
                        <v-col cols="4" sm="2" class="text-center" v-if="formItemReclamaciones.idEstado == 4">
                            <v-btn dark @click="disableAnalisis = true" color="primary darken-1" small>continuar</v-btn></v-col
                        >
                        <v-col cols="4" sm="2" class="text-center" v-else>
                            <v-btn dark @click="addEstadoComplaints" color="primary darken-1" small>{{ isEdit ? 'actualizar' : 'continuar' }}</v-btn></v-col
                        >
                        <v-dialog v-model="disableAnalisis" max-width="290">
                            <v-card>
                                <v-card-text class="py-3">
                                    <h4><strong>Registrar resultado del Reclamo</strong></h4>
                                </v-card-text>
                                <v-card-text>
                                    <v-select
                                        v-model="formItemReclamaciones.result"
                                        :items="listResult"
                                        auto-grow
                                        :error-messages="errors.collect('formItemReclamaciones.result')"
                                        data-vv-name="formItemReclamaciones.result"
                                        rows="1"
                                        row-height="30"
                                        v-validate="'required'"
                                        item-text="description"
                                        item-value="id"
                                        dense
                                        hide-details="auto"
                                        label="Resultado"
                                        required
                                    />
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" @click="addEstadoComplaints" :loading="loadingAnalisis" text> REGISTRAR </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-container>
            </form>
        </v-col>

        <v-col cols="12" class="justify-center align-center">
            <v-data-table
                multi-sort
                dense
                :loading="loading"
                :headers="headersComplaints"
                :items="listEstadosReclamos"
                :items-per-page="10"
                class="elevation-1"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon v-if="item.estado === 'NUEVO'" color="primary" small @click="getItem(item)">mdi-pencil</v-icon>
                </template>
                <template v-slot:[`item.fechaMovimiento`]="{ item }">
                    {{ item.fechaMovimiento | formatDateHour }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
import { mixins } from '@/mixins/mixin.js'
import DefaultModelComplaints from '@/models/reclamaciones'
import { mapState, mapGetters, mapActions } from 'vuex'
import { format, parseISO, differenceInDays } from 'date-fns'

export default {
    mixins: [mixins],
    props: ['loading', 'step', 'dataComplaints'],
    filters: {
        formatDateHour(val) {
            const today = format(val)
            return format(today, 'DD/MM/YYYY HH:mm a')
        }
    },
    computed: {
        ...mapGetters({
            listaEstadosCom: 'estados/listaComplaints',
            optionalRole: 'usuarios/optionalRole',
            listUsuAssign: 'usuarios/listUsuAssign',
            listaEstadoComplaints: 'reclamaciones/listaEstadoReclamaciones'
        }),
        ...mapState('prospectos', ['datos']),
        ...mapState('usuarios', ['userLogin', 'listaUsuariosAsociados', 'roles']),

        listEstadosReclamos() {
            const items = []
            const data = this.listaEstadoComplaints
            console.log('data', data)
            let demora
            data.sort((a, b) => a.id - b.id).map((x, i) => {
                const duration = differenceInDays(new Date(x.fechaMovimiento), demora)
                items.push({
                    ...x,
                    estado: this.listaEstadosCom.find((y) => y.id == x.idEstado).descripcion,
                    nombreUsuarioModificacion: x.usuarioModificacion,
                    demora: duration ? duration : '-'
                })
                demora = new Date(x.fechaMovimiento)
            })
            console.log('items', items)
            return items
        },
        headersProspectos() {
            return [
                { text: 'fechaMovimiento', value: 'fechaMovimiento' },
                { text: 'Estado', value: 'estado' },
                { text: 'Detalle', value: 'descripcion' },
                { text: 'usuarioModificacion', value: 'nombreUsuarioModificacion' }
            ]
        },
        headersComplaints() {
            return [
                { text: 'actions', value: 'actions' },

                { text: 'fechaMovimiento', value: 'fechaMovimiento' },
                { text: 'DEMORA (Días)', value: 'demora' },
                {
                    text: 'AREA',
                    value: 'area'
                },
                { text: 'Estado', value: 'estado' },
                { text: 'Detalle', value: 'descripcion' },
                { text: 'usuarioModificacion', value: 'nombreUsuarioModificacion' }
            ]
        }
    },
    data() {
        return {
            isEdit: false,
            disableAnalisis: false,
            disableTc: false,
            menu: false,
            loadingAnalisis: false,
            menu2: false,
            listResult: [
                { id: '00', description: 'PROCEDE' },
                { id: '01', description: 'NO PROCEDE' }
            ],
            recibido: null,
            formItemReclamaciones: DefaultModelComplaints.BaseFormItem()
        }
    },
    methods: {
        ...mapActions({
            handleGetEstados: 'estados/obtenerLista',
            handleGetEstadosComplaints: 'estados/obtenerListaComplaints',
            handleGetEstadosReclamaciones: 'reclamaciones/getEstado',
            handleGetUsuarios: 'usuarios/obtenerLista'
        }),

        addEstadoComplaints() {
            console.log('log')
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.loadingAnalisis = true
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'guardando estado'
                    })
                    const usuarioModificacion = this.formItemReclamaciones.usuarioModificacion
                        ? this.formItemReclamaciones.usuarioModificacion
                        : this.userLogin.usuario

                    console.log('usuarioModificacion ', usuarioModificacion)
                    console.log('this.dataComplaints', this.dataComplaints)
                    const fechaMovimiento = format(new Date())
                    const body = {
                        ...this.formItemReclamaciones,
                        usuarioModificacion,
                        fechaMovimiento
                    }
                    console.log('this.formItemReclamaciones ', body)
                    if (this.isEdit) {
                        await this.$store.dispatch('reclamaciones/actualizarEstadoReclamaciones', {
                            ...this.dataComplaints,
                            ...body
                            // idComplaints: this.dataComplaints.id,
                        })
                    } else {
                        await this.$store.dispatch('reclamaciones/registrarEstadoReclamaciones', {
                            ...this.dataComplaints,
                            ...body
                            // idComplaints: this.dataComplaints.id,
                        })
                    }

                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                    this.formItemReclamaciones = DefaultModelComplaints.BaseFormItem()
                    this.loadingAnalisis = false
                    this.disableAnalisis = false
                    await this.handleGetEstadosReclamaciones(this.dataComplaints)
                    this.isEdit = false
                }
            })
        },
        async getItem(item) {
            this.isEdit = true
            this.formItemReclamaciones = item
        }
    },

    async created() {
        this.isEdit = false
        console.log('this.datos //', this.datos)
        this.formItemReclamaciones = DefaultModelComplaints.BaseFormItem()
        await this.handleGetEstadosComplaints({})
        await this.handleGetEstadosReclamaciones(this.dataComplaints)
    }
}
</script>
