<template>
    <v-container fluid>
        <v-row>
            <v-container fluid>
                <v-card>
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col cols="12" sm="8" align="start" justify="center">
                                <v-row class="grey lighten-4" align="start" justify="center">
                                    <v-col cols="12"> <strong>FILTROS</strong></v-col>
                                    <v-col cols="12" sm="4">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template #activator="{ on, attrs }">
                                                <v-text-field
                                                    clearable
                                                    v-model="fechaFiltro"
                                                    hide-details="auto"
                                                    dense
                                                    label="FILTRAR POR RANGO - MES"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                                                <v-spacer />
                                                <v-btn text color="primary" @click="menu = false"> CERRAR </v-btn>
                                                <v-btn color="primary" @click="filtrarProspecto"> BUSCAR </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-divider inset vertical></v-divider>
                                    <v-col cols="6" sm="4">
                                        <v-autocomplete
                                            clearable
                                            v-model="bussines"
                                            dense
                                            hide-details="auto"
                                            outlined
                                            :items="_bussines"
                                            item-text="description"
                                            item-value="id"
                                            label="Empresa"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-text-field clearable dense outlined v-model="isHojaReclamo" label="Hoja de Reclamo" required></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-autocomplete
                                            clearable
                                            v-model="isSelectDepartamento"
                                            dense
                                            hide-details="auto"
                                            outlined
                                            v-validate="{ required: !optionalRole.allLocation }"
                                            :required="optionalRole.allLocation ? false : true"
                                            :error-messages="errors.collect('isSelectDepartamento')"
                                            data-vv-name="isSelectDepartamento"
                                            data-vv-as="departamento"
                                            :items="departamentoAssign"
                                            item-text="descripcion"
                                            item-value="idDepa"
                                            label="Buscar por Departamento"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-text-field clearable v-model="isName" dense hide-details="auto" outlined label="Nombre y Apellido"> </v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-text-field
                                            clearable
                                            dense
                                            outlined
                                            v-model="isNumeroDocumento"
                                            label="DNI DEL CLIENTE"
                                            required
                                            placeholder="Numero de Documento"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-text-field clearable v-model="isProductServicio" dense hide-details="auto" outlined label="Producto o Servicio">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-autocomplete
                                            clearable
                                            v-model="isTipoReclamo"
                                            dense
                                            hide-details="auto"
                                            outlined
                                            :items="tipoReclamo"
                                            item-text="label"
                                            item-value="value"
                                            label="Tipo de Reclamo o Queja"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-autocomplete
                                            clearable
                                            v-model="isTipoNotificacion"
                                            dense
                                            hide-details="auto"
                                            outlined
                                            item-text="label"
                                            item-value="label"
                                            :items="tipoNotificacion"
                                            label="Tipo Notificacion"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-btn small color="primary" dark @click="filtrarProspecto"> FILTRAR</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-row>
                                    <v-col cols="12"> <strong>ACTIONS</strong></v-col>
                                    <v-col cols="12" align="start" justify="center">
                                        <v-btn class="mx-1" small color="green" dark @click="downloadHandle">
                                            DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                                        </v-btn>
                                        <v-btn class="mx-1" small color="blue" dark @click="createHandle">
                                            CREAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <ReclamoManualNew
                            v-if="createDialog"
                            :open="true"
                            :step="step"
                            :textButton="modalText"
                            @closeModal="closeModal"
                            @reload="fnReload"
                            :reclamo="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title dark>
                        <strong>LIBRO DE RECLAMACIONES</strong>
                        <v-spacer />
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            item-key="id"
                            flat
                            :headers="headers"
                            :items="listaReclamaciones()"
                            :search="search"
                            :loading="loading"
                            :sort-by="['id']"
                            :sort-desc="[true]"
                            loading-text="...Cargando"
                        >
                            <!-- <template v-slot:[`item.fechaCreacion`]="{ item }">
                                {{ item.fechaCreacion | fechaISO }}
                            </template> -->
                            <template v-slot:[`item.stateResult`]="{ item }">
                                <v-chip-group column multiple>
                                    <v-chip
                                        v-if="item.descriptionEstado"
                                        :color="item.descriptionEstado == 'NUEVO' ? 'green' : item.descriptionEstado == 'FINALIZADO' ? 'red' : 'orange'"
                                        dark
                                    >
                                        {{ item.descriptionEstado }}
                                        <template v-if="item.result">
                                            <v-icon class="mx-2" small v-if="item.result == '00'" right dark> mdi-thumb-up </v-icon>
                                            <v-icon class="mx-2" small v-else right dark> mdi-thumb-down </v-icon>
                                        </template>
                                    </v-chip>
                                </v-chip-group>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon color="green" @click="openForm(1, item)"> mdi-eye </v-icon>
                                <v-icon color="blue" @click="openForm(2, item)"> mdi-pencil </v-icon>
                                <v-icon color="red" @click="openForm(3, item)"> mdi-delete </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <ReclamoManualNew
            v-if="openReclamaciones"
            :open="openReclamaciones"
            :step="step"
            :textButton="modalText"
            :tipo="tipo"
            @closeModal="closeModal"
            @reload="fnReload"
            :reclamo="true"
        />
        <Delete :open="openDelete" :loadingBTN="loadingBTN" @handle-delete="fnHandlePut" @closeModal="closeDocumento" />
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { exportXLSX } from '@/utils'
import { mixins } from '@/mixins/mixin.js'
import Delete from '@/components/modals/Delete'
import ReclamoManualNew from '@/components/modals/ReclamoManualNew'
import Documento from '@/components/modals/Documento'
import { format, subDays } from 'date-fns'
import { getFormatId } from '@/utils/genericUtils'

const defaultText = 'REGISTRAR'

export default {
    components: {
        Delete,
        Documento,
        ReclamoManualNew
    },
    mixins: [mixins],

    data() {
        return {
            listResult: [
                { id: '00', description: 'PROCEDE' },
                { id: '01', description: 'NO PROCEDE' }
            ],
            createDialog: false,
            bussines: null,
            isSelectDepartamento: null,
            isNumeroDocumento: null,
            isProduct: null,
            isTipoReclamo: null,
            isTipoNotificacion: null,
            isHojaReclamo: null,
            isName: null,
            isProductServicio: null,
            menu: false,
            tipo: null,
            fechaFiltro: [format(subDays(new Date(), 90), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')],
            openDialog: false,
            openReclamaciones: false,
            openDelete: false,
            openDocumento: false,
            search: '',
            singleSelect: true,
            loadingBTN: false,
            selected: null,
            modalText: defaultText,
            step: 1,
            loading: false,
            headers: [
                { text: 'ACTION', value: 'actions', sortable: false },
                { text: 'ESTADOS - RESULTADO', value: 'stateResult' },
                { text: 'HOJA DE RECLAMO', value: 'hojaReclamo' },
                { text: 'TIPO DE RECLAMO', value: 'typeClaims' },
                { text: 'EMPRESA', value: 'bussines' },
                { text: 'FECHA', value: 'fechaCreacion' },
                { text: 'DEPARTAMENTO', value: 'departamento' },
                { text: 'NOMBRE Y APELLIDO', value: 'nombreCompletos' },
                { text: 'NUMERO DOCUMENTO', value: 'numeroDocumento' },
                { text: 'PRODUCTO / SERVICIO', value: 'altProductoServicio' },
                { text: 'TIPO MEDIO', value: 'typeHalf' },
                { text: 'MEDIO', value: 'half' },
                { text: 'DETALLE', value: 'description' },
                { text: 'PEDIDO', value: 'order' }
            ]
        }
    },
    watch: {
        step(val) {
            if (val == 1) {
                this.modalText = defaultText
            } else if (val == 3) {
                this.modalText = 'ELIMINAR'
            } else {
                this.modalText = 'VISUALIZAR'
            }
        },
        async isSelectDepartamento(val) {
            console.log('isSelectDepartamento --', val)
            if (val) {
                await this.handleGetProvincias({ id: val })
            }
        }
    },
    computed: {
        ...mapGetters('reclamaciones', ['lista']),
        ...mapState('reclamaciones', [
            'datos',
            'listBussines',
            'listPremises',
            'itemsDocumento',
            'tipoBien',
            'tipoReclamo',
            'tipoNotificacion',
            'tipoDetalleReclamo'
        ]),
        ...mapGetters('usuarios', ['optionalRole']),
        ...mapGetters({ listProduct: 'productos/lista' }),
        ...mapState('utilities', ['_bussines', 'tipoDocumento']),
        ...mapGetters('ubigeo', ['departamentoAssign'])
    },
    methods: {
        ...mapMutations({
            UPDATE_DATOS: 'reclamaciones/UPDATE_DATOS',
            RESET_FORM: 'reclamaciones/RESET_FORM',
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        ...mapActions({
            handleGet: 'reclamaciones/obtenerLista',
            handlePut: 'reclamaciones/actualizar',
            handleGetDepartamentos: 'ubigeo/obtenerListaDepartamentos',
            handleGetBussines: 'reclamaciones/bussines',
            handleGetPremises: 'reclamaciones/premises',
            handleGetProduct: 'productos/obtenerLista',
            handleGetProvincias: 'ubigeo/obtenerListaPronvincias'
        }),

        async closeModal(nombreModal) {
            console.log('nombreModal', nombreModal)
            this.EMPTY_FORM()
            this[nombreModal] = false
        },
        async fnHandlePut() {
            this.loadingBTN = true
            const item = this.selected
            if (this.step == 3) {
                item.activo = 0
            }
            await this.handlePut({ ...item, activo: 0 })
            await this.fnHandleGet({})
            this.EMPTY_FORM()
        },
        async fnReload() {
            console.log('reload')
            // await this.fnHandleGet({ idDepartamento: this.isSelectDepartamento })
            this.estadoSeleccionado = 0
        },
        async filtrarProspecto() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    await this.fnHandleGet({
                        idBussines: this.bussines,
                        idDepartamento: this.isSelectDepartamento,
                        idNumeroDocumento: this.isNumeroDocumento,
                        idProduct: this.isProductServicio,
                        idTipoReclamo: this.isTipoReclamo,
                        idTipoNotificacion: this.isTipoNotificacion,
                        idHojaReclamo: this.isHojaReclamo,
                        idName: this.isName
                    })
                    this.menu = false
                    this.estadoSeleccionado = 0
                }
            })
        },
        listaReclamaciones() {
            const myList = []
            console.log('.this.,lista', this.lista)
            this.lista.map((x) => {
                if (x.activo == 1) {
                    myList.push({
                        ...x,
                        order: x.order ? x.order.substr(0, 15) : '',
                        isPedido: x.order,
                        isAltProductoServicio: x.altProductoServicio,
                        isMonto: x.monto,
                        isIdPremises: x.idPremises,
                        isAltPremises: x.altPremises,
                        isTipoReclamoQueja: x.type,
                        isDescripcionReclamo: x.description,
                        isTipoBien: x.typeWell,
                        isTipoReclamacion: x.typeClaims,
                        isOtroTipoReclamo: x.typeRequest,
                        description: x.description ? x.description.substr(0, 10) : '',
                        hojaReclamo: x.idComplaintsExternal ? `${getFormatId(x.id)} | ${x.idComplaintsExternal}` : getFormatId(x.id),
                        tipoDocumento: this.tipoDocumento.find((y) => y.id == x.idTipoDocumento).text,
                        typeNotification: this.tipoNotificacion.find((y) => y.label == x.typeNotification)?.label,
                        typeClaims: this.tipoReclamo.find((y) => y.value == x.typeClaims).label,
                        type: this.tipoDetalleReclamo.find((y) => y.value == x.type).label,
                        typeWell: this.tipoBien.find((y) => y.value == x.typeWell).label,
                        resultDescription: x.result ? (x.result == '00' ? 'PROCEDE' : 'NO PROCEDE') : '',
                        typeHalf: x.typeNotification,
                        half: x.typeNotification == 'DOMICILIO' ? x.direccion : x.correo
                    })
                }
            })
            return myList
        },
        EMPTY_FORM() {
            this.openReclamaciones = false
            this.openDialog = false
            this.loading = false
            this.loadingBTN = false
            this.menu = false
            this.selected = null
            this.openDelete = false
            this.openDocumento = false
            this.OPEN_MODAL({ state: false })
            this.step = 1
            this.RESET_FORM()
        },
        openForm(tipo, item) {
            this.step = tipo
            let status = false
            this.selected = item
            console.log('tipo', tipo)
            this.tipo = tipo
            if (!item) {
                this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
            } else if (tipo == 3) {
                if (!item) {
                    this.OPEN_BAR({ state: true, text: 'No hay selección', color: 'red' })
                } else {
                    this.openDelete = true
                }
            } else {
                status = true
                console.log('item ', item)

                this.UPDATE_DATOS(item)
            }
            this.openReclamaciones = status
        },

        async fnHandleGet(payload) {
            const {
                idBussines = null,
                idDepartamento = null,
                idNumeroDocumento = null,
                idProduct = null,
                idTipoReclamo = null,
                idTipoNotificacion = null,
                idHojaReclamo = null,
                idName = null
            } = payload
            this.loading = true
            console.log('this.fechaFiltro', this.fechaFiltro)
            await this.handleGet({
                fechaFiltro: this.fechaFiltro,
                idBussines,
                idDepartamento,
                idNumeroDocumento,
                idProduct,
                idTipoReclamo,
                idTipoNotificacion,
                idHojaReclamo,
                idName
            })
            await this.handleGetProduct({ fechaFiltro: this.fechaFiltro })

            this.EMPTY_FORM()
        },
        createHandle() {
            this.createDialog = true
        },
        downloadHandle() {
            this.loading = true
            const headers = [
                'ESTADOS',
                'RESULTADO',
                'HOJA DE RECLAMO',
                'TIPO DE RECLAMOCIÓN',
                'TIPO RECLAMO',
                'OTRO TIPO DE RECLAMO O QUEJA',
                'DESCRIPCIÓN RECLAMACIÓN',
                'PEDIDO',
                'EMPRESA',
                'FECHA',
                'DEPARTAMENTO',
                'PROVINCIA',
                'DISTRITO',
                'DIRECCIÓN',
                'NOMBRES Y APELLIDOS',
                'TIPO DOCUMENTO',
                'NUMERO DE DOCUMENTO',
                'TELEFONO',
                'CORREO',
                'TIPO BIEN',
                'PRODUCTO / SERVICIO',
                'MONTO',
                'SEDE',
                'OTRA SEDE',
                'TIPO NOTIFICACION',
                'estados_con_fechas'

            ]
            const filename = `REPORT_RECLAMACIONES_${Date.now()}`
            exportXLSX(
                {
                    headers,
                    campos: [
                        'descriptionEstado',
                        'resultDescription',
                        'hojaReclamo',
                        'typeClaims',
                        'type',
                        'typeRequest',
                        'isDescripcionReclamo',
                        'isPedido',
                        'bussines',
                        'fechaCreacion',
                        'departamento',
                        'provincia',
                        'distrito',
                        'direccion',
                        'nombreCompletos',
                        'tipoDocumento',
                        'numeroDocumento',
                        'telefono',
                        'correo',
                        'typeWell',
                        'altProductoServicio',
                        'monto',
                        'local',
                        'altPremises',
                        'typeNotification',
                        'estados_con_fechas'

                    ],
                    arrayData: this.listaReclamaciones()
                },
                { filename }
            )
            this.loading = false
        },

        closeDocumento(nombreModal) {
            this[nombreModal] = false
        }
    },
    async created() {
        await Promise.all([await this.handleGetDepartamentos(), await this.handleGetBussines(), await this.handleGetPremises(), await this.fnHandleGet({})])
    }
}
</script>
